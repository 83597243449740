<template>
  <div class="order-detail">
    <div class="content">
      <template v-if="!isBooked">
        <div class="book-title">预约信息填写</div>
        <van-form>
          <van-cell-group class="book-info">
            <van-field
              v-model="bookInfo.name"
              :rules="[{ required: true, message: '请填写中文姓名' }]"
              label="中文姓名"
              placeholder="请输入中文姓名"
            />
            <van-field
              v-model="bookInfo.enName"
              :rules="[{ required: true, message: '请填写英文姓名' }]"
              label="英文姓名"
              placeholder="请输入英文姓名"
            />
            <van-field label="称谓" placeholder="请选择称谓">
              <template #input>
                <van-radio-group v-model="bookInfo.sex" direction="horizontal">
                  <van-radio :name="1">先生</van-radio>
                  <van-radio :name="2">女士</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-model="bookInfo.phone"
              label="手机号"
              maxlength="11"
              placeholder="请输入手机号"
              :rules="[{ required: true, message: '请输入手机号' }, { pattern: phoneReg, message: '手机号格式错误' }]"
            />
            <van-field
              v-model="bookInfo.email"
              label="邮箱"
              placeholder="请输入邮箱"
              :rules="[{ required: true, message: '请输入邮箱' }, { pattern: emailReg, message: '邮箱格式错误' }]"
            />
            <van-cell title="预约日期" :value="bookInfo.date" @click="onChooseBookTime" />
          </van-cell-group>
        </van-form>
        <van-divider />
      </template>
      <van-cell-group>
        <van-cell
          :title="info.hoteltitle"
          size="large"
          :label="info.roomltitle"
        >
          <template #extra>
            <van-button class="refuse-btn" size="mini" @click="handleRefuse" v-if="isShowRefuseBtn">退款</van-button>
          </template>
        </van-cell>
        <van-cell title="付款价格" :value="info.amount" size="large" />
        <van-cell title="订单时间" :value="info.timer" size="large" />
        <van-cell title="可预约时间" :value="bookTimeText" size="large" />
        <van-cell title="状态" :value="statusText" size="large" />
        <van-cell title="酒店电话" size="large" @click="handlePhone">{{ info.hotelphone }}</van-cell>
        <template v-if="isShowBookInfo">
          <van-cell title="中文姓名" :value="info.consigneeman" size="large" />
          <van-cell title="英文姓名" :value="info.letter" size="large" />
          <van-cell title="称谓" :value="sexText" size="large" />
          <van-cell title="手机号" :value="info.consigneemobile" size="large" />
          <van-cell title="邮箱" :value="info.consigneemail" size="large" />
          <van-cell title="预约日期" :value="bookDate || '-'" size="large" />
        </template>
      </van-cell-group>
    </div>
    <van-calendar
      v-model="showCalendar"
      @confirm="onConfirmCalendar"
      :min-date="info.starttime"
      :max-date="info.endtime"
      :formatter="calendarFormatter"
      ref="calendar"
    />
    <van-goods-action>
      <van-goods-action-button
        v-if="!isBooked"
        type="danger"
        text="立即预约"
        :disabled="bookBtnDisabled"
        @click="onClickBookingButton"
      />
    </van-goods-action>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { pinyin } from 'pinyin-pro'
import { getBookDetail, saveBookDetail, refuseOrder } from '../api'
import { SESSION_NAME } from '../utils/login'
export default {
  name: 'order-detail',
  data() {
    return {
      bookBtnDisabled: false,
      bookInfo: {
        name: '',
        enName: '',
        sex: 1,
        phone: '',
        email: '',
        date: null
      },
      info: {
        amount: 0,
        apmtid: '',
        endtime: new Date(),
        hotelphone: '-',
        hoteltitle: '-',
        notime: '',
        roomltitle: '-',
        starttime: new Date(),
        status: '',
        timer: '',
        starttimeText: '',
        endtimeText: '',
        price: '',
        consigneemail: '',
        consigneeman: '',
        consigneemobile: '',
        consigneesex: '',
        content_id: '',
        letter: '',
        timer_start: ''
      },
      phoneReg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      emailReg: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
      showCalendar: false,
      bookingDate: '',
      isBooked: true,
      isShowRefuseBtn: false,
      isRefusingRequest: false
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    },
    formatShowDate(date) {
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    },
    handlePhone() {
      location.href = `tel:${this.info.hotelphone}`
    },
    onConfirmCalendar(date) {
      this.showCalendar = false
      this.bookInfo.date = this.formatDate(date)
    },
    onChooseBookTime() {
      this.showCalendar = true
    },
    onClickBookingButton() {
      // 验证预约信息
      if (!this.bookInfo.name) {
        this.$toast('请输入中文姓名')
        return
      }
      if (!this.bookInfo.enName) {
        this.$toast('请输入英文姓名')
        return
      }
      if (!this.phoneReg.test(this.bookInfo.phone)) {
        this.$toast('手机号格式有误')
        return
      }
      if (!this.emailReg.test(this.bookInfo.email)) {
        this.$toast('邮箱格式有误')
        return
      }
      if (!this.emailReg.test(this.bookInfo.email)) {
        this.$toast('邮箱格式有误')
        return
      }
      if (!this.bookInfo.date) {
        this.$toast('请选择预约日期')
        return
      }
      Dialog.confirm({
        title: '预约确认',
        message: `确定预约吗`,
      }).then(() => {
        this.bookBtnDisabled = true
        this.handleBookInfo()
      }).catch(() => {
        // on cancel
      });
    },
    calendarFormatter(day) {
      const year = day.date.getFullYear()
      const month = (day.date.getMonth() + 1).toString().padStart(2, '0')
      const date = day.date.getDate().toString().padStart(2, '0')

      const dateStr = `${year}-${month}-${date}`

      if (this.info.notime.split(',').includes(dateStr)) {
        day.type = 'disabled'
      }

      return day
    },
    handleRefuse() {
      if (this.isRefusingRequest) return

      Dialog.confirm({
        title: '退款确认',
        message: '确认要退款吗'
      }).then(() => {
        this.isRefusingRequest = true
        refuseOrder({
          token: localStorage.getItem(SESSION_NAME),
          apmtid: this.info.apmtid,
          refund: this.info.price
        }).then(() => {
          this.$toast('退款申请成功')
          this.handleGetBookDetail()
        }).finally(() => {
          this.isRefusingRequest = false
        })
      })
    },
    handleBookInfo() {
      const { name, sex, phone, email, enName, date } = this.bookInfo
      const { apmtid } = this.info
      saveBookDetail({
        token: localStorage.getItem(SESSION_NAME),
        apmtid: apmtid,
        consigneeman: name,
        consigneesex: sex,
        consigneemobile: phone,
        consigneemail: email,
        letter: enName,
        timer_start: date
      }).then(() => {
        this.$toast('预约成功')
        this.handleGetBookDetail()
      }).finally(() => {
        this.bookBtnDisabled = false
      })
    },
    handleGetBookDetail() {
      getBookDetail({
        token: localStorage.getItem(SESSION_NAME),
        apmtid: this.$route.query.id
      }).then(res => {
        const { data } = res
        this.isBooked = data.status != 1
        this.isShowRefuseBtn = data.status == 1
        if (data) {
          Object.keys(this.info).forEach(key => this.info[key] = data[key])
          this.info.timer = this.formatShowDate(new Date(this.info.timer * 10e2))
          if (this.info.starttime) {
            const starttimeArr = this.info.starttime.split(' ')[0].split('-') || [0, 0, 0]
            this.info.starttime = new Date(starttimeArr[0], starttimeArr[1] - 1, starttimeArr[2])
          }
          if (this.info.endtime) {
            const endtimeArr = this.info.endtime.split(' ')[0].split('-') || [0, 0, 0]
            this.info.endtime = new Date(endtimeArr[0], endtimeArr[1] - 1, endtimeArr[2])
          }
          this.info.starttimeText = data.starttime
          this.info.endtimeText = data.endtime
        }
      })
    }
  },
  mounted() {
    this.handleGetBookDetail()
  },
  watch: {
    'bookInfo.name': {
      handler(v) {
        this.bookInfo.enName = pinyin(v, { toneType: 'none', type: 'array' }).join('').toUpperCase()
      },
      deep: true
    }
  },
  computed: {
    bookTimeText() {
      return [(this.info.starttimeText && this.info.starttimeText != '0000-00-00 00:00:00') ? this.info.starttimeText : '即日起', this.info.endtimeText].join('-')
    },
    sexText() {
      return this.info.consigneesex == 1 ? '先生' : '女士'
    },
    bookDate() {
      if (this.info.timer_start) {
        const d = new Date(+this.info.timer_start * 10e2)
        return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
      }
      return ''
    },
    statusText() {
      switch(+this.info.status) {
        case 1:
          return '待预约'
        case 2:
          return '预约中'
        case 3:
          return '已预约'
        case 4:
          return '预约失败'
        case 5:
          return '已完成'
        case 6:
          return '已退款'
        case 7:
          return '退款中'
        default:
          return ''
      }
    },
    isShowBookInfo() {
      return [2, 3, 5].includes(+this.info.status)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-detail {
  padding-top: 48px;

  .content {
    padding: 5px 0;
  }

  .title {
    font-size: 24px;
    padding: 5px 0;
  }

  .text {
    font-size: 20px;
    padding: 5px 0;
  }

  .desc {
    font-size: 20px;
    padding: 5px 0;
    color: #666;
  }

  .refuse-btn {
    margin-left: 10px;
  }

  .book-title {
    font-size: 14px;
    padding: 10px;
  }
}
</style>